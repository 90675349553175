import React from "react";
import { View, StyleSheet } from "react-native";
import NtMaterialIcon from "../../components/NtMaterialIcon";

function NtChannelIcon({ size = 23, channel, color, containerStyle }) {
  return (
    <View style={[styles.container, containerStyle]}>
      <NtMaterialIcon
        name={channel?.icon}
        color={color || channel.color}
        size={size}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},
});

export default NtChannelIcon;
