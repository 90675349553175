import colors from "../../config/colors";
import settings from "../../config/settings";

export const messagechanneltypes = [
  {
    id: "app",
    title: "Cruise Line App",
    icon: "ship-wheel",
    service: settings.messagingService,
    color: colors.almostBlack,
  },
  {
    id: "sms",
    title: "SMS",
    icon: "message-outline",
    service: "omni.sms.nettalkmaritime.com",
    color: "green",
  },
  {
    id: "whatsapp",
    title: "WhatsApp",
    icon: "whatsapp",
    service: "omni.whatsapp.nettalkmaritime.com",
    color: "green",
  },
  {
    id: "imessage",
    title: "Apple Messages for Business",
    icon: "apple",
    service: "omni.apple.nettalkmaritime.com",
    color: "green",
  },
  {
    id: "email",
    title: "Email",
    icon: "email",
    service: "omni.email.nettalkmaritime.com",
    color: "red",
  },
];
